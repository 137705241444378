import ContactUs from './ContactUs.react';

export default {
  components: {
    ContactUs,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
