import React, { useMemo, useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import AudioIcon from '~reactComponents/Icons/Audio.icon.react';

import { ContactUsInput } from './ContactUs.types.react';
import { IRouter, IStore } from '~globalTypes';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { strings } from '~utils/strings';
import { Title, ThinTitle, CardTitleDefault } from '~utils/texts.react';

import './ContactUs.react.scss';

const validationSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  subject: Joi.string().required(),
  help: Joi.string().required(),
  captcha: Joi.string().optional(),
});

type ContactUsProps = {
  router: () => IRouter;
  store: () => IStore;
};

const ContactUs = ({ router, store }: ContactUsProps) => {
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [captchaId, setCaptchaId] = useState<string | null>(null);
  const { user } = store().getters;

  const email = useMemo(() => user && user.Email, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactUsInput>({
    resolver: joiResolver(validationSchema),
    mode: strings.noTranslate.onSubmit,
    defaultValues: useMemo(() => {
      return { email };
    }, [email]),
  });

  useEffect(() => {
    const previousCaptchaId = localStorage.getItem('captchaId');
    if (previousCaptchaId) {
      deleteCaptchaId(previousCaptchaId);
    }
    getCaptchaId();
    return () => {
      deleteCaptchaId(captchaId);
    };
  }, []);

  const getCaptchaId = async () => {
    let result;
    try {
      result = await sendRequest('GET', '/v0/captcha/generate?from=contact-us', {}, null);
      setCaptchaId(result?.captchaId === strings.contactUs.noCaptcha ? null : result.captchaId);
      if (result?.captchaId !== strings.contactUs.noCaptcha) {
        localStorage.setItem('captchaId', result.captchaId);
      }
    } catch (e) {
      logger.logError('error_getting_captcha_id_contact_us_form', e);
      toast.error(`${strings.contactUs.errorGettingCaptcha} ${strings.general.pleaseTryAgain}`);
    }
  };

  const deleteCaptchaId = async (captchaId: string | null) => {
    if (!captchaId) {
      return;
    }
    try {
      await sendRequest(
        'DELETE',
        `/v0/captcha?captchaId=${captchaId}`,
        { showSuccessAlert: false },
        null,
      );
    } catch (e) {
      logger.logError('error_deleting_captcha_id_contact_us_form', e);
    }
  };

  const onSubmit = async (userInput: ContactUsInput) => {
    let result;

    const data = buildRequestPayload(userInput);

    try {
      result = await sendRequest(
        'POST',
        '/v0/contact-us',
        {
          data,
          showSuccessAlert: false,
        },
        null,
      );
      await deleteCaptchaId(captchaId);
    } catch (e) {
      logger.logError('error_sending_contactUs', e);
      toast.error(
        e?.message
          ? e.message
          : `${strings.contactUs.errorSendingMessage} ${strings.general.pleaseTryAgain}`,
      );
      if (captchaId && captchaId !== strings.contactUs.noCaptcha) {
        await deleteCaptchaId(captchaId);
        await getCaptchaId();
      }
    } finally {
      if (result?.Success) {
        setEmailSent(true);
      }
    }
  };

  const buildRequestPayload = (userInput: ContactUsInput) =>
    captchaId && captchaId !== strings.contactUs.noCaptcha
      ? {
          body: userInput.help,
          email: userInput.email,
          subject: userInput.subject,
          captchaId: captchaId,
          captcha: userInput.captcha,
          from: strings.contactUs.from,
        }
      : {
          body: userInput.help,
          email: userInput.email,
          subject: userInput.subject,
        };

  const playAudio = (e) => {
    e.preventDefault();
    if (captchaId) {
      const audio = new Audio(`/v0/captcha?id=${captchaId}&ext=wav&lang=en&download=false`);
      audio.play();
    }
  };

  const ContactForm = () => (
    <>
      <h1
        className={clsx(
          'contact-us-card__title',
          Title({ size: strings.textStyling.xs, bottomMargin: strings.textStyling.medBottom }),
        )}
      >
        {strings.contactUs.title}
      </h1>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="formEmail">
          <Form.Label>{strings.Email}</Form.Label>
          <Form.Control
            type="email"
            className={errors.email && 'is-invalid'}
            {...register('email', { required: true })}
          />
          <Form.Control.Feedback type="invalid">{errors?.email?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formSubject">
          <Form.Label>{strings.contactUs.Subject}</Form.Label>
          <Form.Control
            type="text"
            className={errors.subject && 'is-invalid'}
            {...register('subject', { required: true })}
          />
          <Form.Control.Feedback type="invalid">{errors?.subject?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formHelp">
          <Form.Label>{strings.contactUs.help}</Form.Label>
          <Form.Control
            as="textarea"
            className={errors.help ? 'contact-us_help is-invalid' : 'contact-us_help'}
            {...register('help', { required: true })}
          />
          <Form.Control.Feedback type="invalid">
            {strings.contactUs.helpError}
          </Form.Control.Feedback>
        </Form.Group>
        {captchaId && (
          <Form.Group controlId="formCaptcha">
            <Form.Label>{strings.contactUs.Captcha}</Form.Label>
            <div className="captcha-image">
              <img
                src={`/v0/captcha?id=${captchaId}&ext=png&lang=en&download=false`}
                alt={captchaId}
              />
              <AudioIcon onClick={playAudio} aria-label="Captcha Audio Icon" />
            </div>
            <Form.Control
              type="text"
              className={errors.captcha && 'is-invalid'}
              {...register('captcha', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {strings.contactUs.helpError}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Button variant="primary" type="submit">
          {strings.general.Submit}
        </Button>
      </Form>
      <Toaster />
    </>
  );

  const EmailSent = () => (
    <div className="contact-us-card__title">
      <h1
        className={Title({
          size: 'xxl',
          weight: strings.textStyling.medium,
          color: 'success',
          bottomMargin: strings.textStyling.lgBottom,
        })}
      >
        {strings.contactUs.emailSent}
      </h1>
      <h2
        className={Title({
          size: strings.textStyling.md,
          weight: strings.textStyling.medium,
          color: strings.textStyling.primary,
          bottomMargin: strings.textStyling.lgBottom,
        })}
      >
        {strings.contactUs.confirmTitle}
      </h2>
      <h3
        className={ThinTitle({
          bottomMargin: strings.textStyling.lgBottom,
          color: strings.textStyling.dropdown,
        })}
      >
        {strings.contactUs.responseSubtitle}
      </h3>
      <h3
        className={ThinTitle({
          bottomMargin: strings.textStyling.lgBottom,
          color: strings.textStyling.dropdown,
        })}
      >
        {strings.contactUs.helpfulLinks}
      </h3>
      <ul
        className={ThinTitle({
          bottomMargin: strings.textStyling.lgBottom,
          color: strings.textStyling.dropdown,
        })}
      >
        <li>
          <a href={router().resolve({ name: 'home' }).href}>{strings.Home}</a>
        </li>
        <li>
          <a href="https://insights.docs.fairwinds.com/" target="_blank">
            {strings.Docs}
          </a>
        </li>
        <li>
          <a href="https://www.fairwinds.com/fairwinds-insights-faq" target="_blank">
            {strings.navigation.FAQ}
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <Container className="contact-us" fluid="md">
      <Card className="contact-us-card">{emailSent ? <EmailSent /> : <ContactForm />}</Card>
    </Container>
  );
};

export default ContactUs;
